.reviews {
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  @include Shape(500px, 100%);
  flex-direction: column;
  text-align: center;
  &__title {
    @include Font($font-x, initial, initial, $f-arialRegular, $c-black);
    margin-bottom: 40px;
  }
  &__wrapper {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 40px;
    width: 100%;
  }
  &__box {
    @include Flex();
    @include JustifyContent(center);
    @include Shape(initial, 350px);
    padding: 20px;
    flex-direction: column;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
    border-bottom: 2px solid $c-iron;
  }
  &__user {
    @include Flex();
    @include AlignItems(center);
  }
  &__picture {
    @include Shape(40px, 40px);
  }
  &__name {
    @include Font($font-standard, initial, initial, $f-arialRegular, $c-black);
    margin-left: 20px;
    font-weight: 700;
  }
  &__content {
    @include Font($font-l, initial, initial, $f-Audiowide, $c-black);
    margin-top: 20px;
    text-align: left;
    color: $c-iron;
  }
}

@media only screen and (max-width: 1000px) {
  .reviews {
    height: 1000px;
    &__wrapper {
      flex-direction: column;
    } 
  }
}

@media only screen and (max-width: 600px) {
  .reviews {
    height: 100%;
    padding: 40px 0;
    &__title {
      font-size: $font-standard;
    }
    &__content,
    &__name {
      font-size: $font-l;
    }
    &__box {
      @include Shape(initial, 80%);
    }
  }
}