.navigation {
  @include Flex();
  @include Shape(80px, 100%);
  @include JustifyContent(space-between);
  @include AlignItems(center);
  @include Position(fixed, 0, initial, 0, initial);
  box-shadow: 6px 6px 4px 6px #000000;
  z-index: 1;
  box-sizing: border-box;
  padding: 10px;
  background-color: $c-william;
  &__logo,
  &__icon {
    @include Shape(40px, 40px);
  }
  &__iconHouse {
    @include Shape(30px, 30px);
    margin-bottom: 1px;
  }
  &__iconHamburger {
    @include Shape(30px, 30px);
  }
  &__box {
    @include Flex();
    @include Shape(100%, initial);
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 10px;
  }
  &__button,
  &__buttonMenu {
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    border: none;
  }
  &__buttonMenu {
    @include Shape(30px, 30px);
  }
  &__buttonMenu {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .navigation {
    &__logo,
    &__icon {
      @include Shape(30px, 30px);
    }
    &__iconHouse,
    &__iconHamburger {
      @include Shape(20px, 20px);
    }
    &__iconHamburger {
        position: relative;
        bottom: 2px;
    }
    &__buttonMenu {
      @include Shape(20px, 20px);
      margin-left: 10px;
    }
  }
}
