html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}

* {
  -webkit-touch-callout: none;
}

* {
  -webkit-overflow-scrolling: touch;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

li, ul {
  list-style: none;
}

input::placeholder {
  color: white;
}

.custom-progress-bar {
  background-color: #D50710;
}

.disabled-select {
  pointer-events: none;
  background-color: #e0e0e0;
}

.custom-image {
  height: 100px;
  width: 70px;
  object-fit: cover;
}

.custom-option {
  display: flex;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-option:hover {
  background-color: #CEE2EA;
}

textarea {
  resize: none;
}

@media only screen and (max-width: 1200px) {
  .react-calendar {
    width: 100%;
    overflow-y: auto;
    margin-top: 10px;
  }
}