$c-william : #3B5B66;
$c-bismark : #4F7582;
$c-botticelli : #CEE2EA;
$-zigguart : #B5D0DA;
$c-white: #FFFFFF;
$c-black: #000000;
$c-grey : rgb(51, 51, 51);
$c-cornflower : #85CCE6;
$c-iron: #adadad;

$f-arialRegular: 'Roboto Flex', sans-serif;
$f-Audiowide: 'Audiowide', cursive;
$f-Bungee: 'Bungee Spice', cursive;
$f-Diplomata: 'Diplomata', cursive;
$f-Oleo: "Oleo Script", serif;

$font-x: 45px;
$font-standard-max: 30px;
$font-standard: 25px;
$font-l: 13px;