.innovations {
  @include Flex();
  @include Shape(400px, 100%);
  position: relative;
  &__image {
    @include Position(absolute, 0, 0, 0, 0);
    @include Shape(100%, 100%);
    background-image: url("../../assets/technology.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
  }
  &__wrapper {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    @include Shape(100%, 100%);
    flex-direction: column;
    position: relative;
  }
  &__title {
    @include Font($font-x, initial, initial, $f-arialRegular, $c-black);
    margin-bottom: 40px;
  }
  &__button {
    @include Font($font-standard, initial, pointer, $f-arialRegular, $c-black);
    background-color: transparent;
    border: 2px solid $c-white;
    color: $c-white;
    padding: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .innovations {
    &__title {
      font-size: $font-standard;
    }
    &__button {
      font-size: $font-l;
    }
  }
}
