.contact {
  @include Flex();
  @include JustifyContent(center);
  @include Shape(400px, 100%);
  background-color: $c-grey;
  gap: 40px;
  &__container {
    @include Flex();
    @include AlignItems(center);
    text-align: left;
    flex-direction: column;
    width: 350px;
    padding: 20px;
    margin-top: 80px;
  }
  &__title {
    @include Font($font-standard, initial, initial, $f-arialRegular, $c-white);
    margin-bottom: 20px;
    width: 100%;
    font-weight: 700;
  }
  &__text {
    @include Font($font-l, initial, initial, $f-arialRegular, $c-white);
    width: 100%;
  }
  &__box {
    margin-top: 20px;
    width: 100%;
  }
  &__list {
    @include Flex();
    @include Font($font-l, initial, initial, $f-arialRegular, $c-white);
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    width: 100%;
    padding: 0;
  }
  &__link {
    cursor: pointer;
  }
  &__socialMedia {
    @include Flex();
    width: 100%;
    gap: 20px;
  }
  &__icon {
    @include Shape(20px, 20px);
  }
  &__item {
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    @include AlignItems(center);
    @include JustifyContent(center);
    flex-direction: column;
    padding: 40px 0;
    height: 100%;
    &__container {
      text-align: center;
      width: 100%;
      padding: 0px;
    }
    &__box {
      margin-top: 20px;
    }
    &__title {
      font-size: $font-standard;
    }
    &__socialMedia {
      @include AlignItems(center);
      @include JustifyContent(center);
    }
  }
}
