.entry {
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  @include Shape(initial, 100%);
  gap: 100px;
  flex-direction: column;
  background-image: url("../../assets/background.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 60px;
  &__title {
    @include Font($font-x, initial, initial, $f-Oleo, $c-white);
    width: 100%;
    text-align: center;
    margin-top: 100px;
    position: relative;
    top: 40px;
  }
  &__desciption {
    @include Font($font-standard, initial, initial, $f-Oleo, $c-white);
    margin-top: 230px;
    position: relative;
    top: 55px;
    width: 500px;
    text-align: center;
    padding: 10px;
    backdrop-filter: blur(5px);
  }
  &__link {
    @include Font($font-standard, initial, pointer, $f-arialRegular, $c-white);
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    flex-direction: column;
    gap: 5px;
    padding: 0 20px;
    text-decoration: none;
  }
  &__box {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    flex-direction: column;
    width: 100%;
  }
  &__boxChild {
    @include Flex();
    @include JustifyContent(space-between);
    @include AlignItems(center);
    width: 80%;
    margin-bottom: 80px;

  }
  &__icon {
    @include Shape(90px, 90px);
    border-radius: 20px;
  }
  &__iconTwo {
    @include Shape(90px, 90px);
    border-radius: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .entry {
    gap: 40px;
    margin-top: 60px;
    height: 100%;
    &__title {
      font-size: $font-standard;
      margin-top: 50px;
      position: relative;
      top: 60px;
    }
    &__desciption {
      font-size: $font-l;
      width: 80%;
      top: 0;
    }
    &__link {
      font-size: $font-l;
      padding: 0 20px;
      width: initial;
    }
    &__boxChild {
      width: 100%;
    }
    &__icon,
    &__iconTwo {
      @include Shape(50px, 50px);
    }
    &__iconTwo {
      margin-bottom: 20px;
    }
  }
}
