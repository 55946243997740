.priceList {
  @include Flex();
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  gap: 10px;
  &__title {
    @include Font($font-standard-max, initial, initial, $f-arialRegular, $c-iron);
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding-bottom: 10px;
    width: 150px;
    border-bottom: 2px solid $c-iron;
  }

  &__description {
    @include Font($font-standard, initial, initial, $f-arialRegular, $c-black);
    margin-top: 20px;
    text-align: center;
    max-width: 600px;

  }

  &__container {
    @include Flex();
    @include JustifyContent(center);
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__box {
    @include Flex();
    flex-direction: column;
    gap: 10px;
  }

  &__boxChild {
    @include Flex();
    @include JustifyContent(space-between);
    padding: 10px;
    border: 1px solid $c-iron;
    border-radius: 5px;
    background-color: $c-white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }

  &__text {
    @include Font($font-l, initial, initial, $f-arialRegular, $c-black);
    padding: 5px;
  }

  &__textPrice {
    @include Font($font-l, initial, initial, $f-arialRegular, $c-black);
    white-space: nowrap;
    font-weight: 700;
    padding: 5px;
  }

  &__closeButton {
    align-self: flex-end;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
    color: $c-black;

    &:hover {
      color: grey;
    }
  }
}

@media only screen and (max-width: 600px) {
  .priceList {
    &__title {
      font-size: $font-standard;
      width: 100px;
    }
    &__boxChild {
      flex-direction: column;
      width: 200px;
    }
    &__description {
      font-size: $font-l;
    }
  }
}
