.experience {
  @include Flex();
  @include Shape(200px, 100%);
  @include JustifyContent(space-between);
  @include AlignItems(center);
  box-sizing: border-box;
  padding: 0 40px;
  background-color: $c-grey;
  text-align: center;
  &__box {
    @include Flex();
    @include AlignItems(center);
    gap: 10px;
    width: 200px;
    height: 140px;
    flex-direction: column;
  }
  &__text,
  &__number {
    @include Flex();
    @include Font($font-standard, initial, initial, $f-arialRegular, $c-white);
  }
  &__number {
    font-size: $font-standard-max;
    font-weight: 700;
  }
}

@media only screen and (max-width: 600px) {
  .experience {
    height: 100%;
    flex-direction: column;
    gap: 40px;
    padding: 40px 0;
    &__box {
      @include JustifyContent(center);
      height: 100%;
    }
    &__number {
      font-size: $font-standard;
    }
    &__text {
      font-size: $font-l;
    }
  }
}