.menuOpen,
.menuClose {
    @include Flex();
    @include Shape(initial, 200px);
    @include Position(absolute, initial, 0, 80px, initial);
    background-color: $c-william;
    flex-direction: column;
    &__list {
        @include Flex();
        @include JustifyContent(center);
        @include AlignItems(center);
        @include Font($font-standard, initial, initial, $f-arialRegular, $c-white);
        margin-top: 10px;
        margin-bottom: 10px;
        gap: 10px;
        padding: 0;
        flex-direction: column;
    }
    &__item {
        cursor: pointer;
    }
}

.menuClose {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .menuOpen,
    .menuClose {
        &__list { 
            font-size: $font-l;
        }
    }
  }