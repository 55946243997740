.aboutUs {
  @include Shape(initial, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  flex-direction: column;

  text-align: center;
  padding: 50px 0;
  gap: 20px;
  &__title {
    @include Font($font-x, initial, initial, $f-arialRegular, $c-black);
    position: relative;
    bottom: 10px;
  }
  &__description {
    @include Font($font-standard, initial, initial, $f-arialRegular, $c-black);
    position: relative;
    bottom: 10px;
    width: 800px;
  }
}

@media only screen and (max-width: 1000px) {
  .aboutUs {
    &__description {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .aboutUs {
    height: 100%;
    &__title {
      font-size: $font-standard;
    }
    &__description {
      font-size: $font-l;
    }
  }
}