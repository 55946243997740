.statistics {
  @include Shape(initial, 100%);
  @include Flex();
  @include JustifyContent(center);
  @include AlignItems(center);
  gap: 50px;
  padding: 50px 0;
  background-color: $c-grey;
  &__box {
    @include Flex();
    @include JustifyContent(center);
    @include AlignItems(center);
    gap: 20px;
    flex-direction: column;
  }
  &__number {
    @include Font(
      $font-standard-max,
      initial,
      initial,
      $f-arialRegular,
      $c-white
    );
  }
  &__text {
    @include Font($font-standard, initial, initial, $f-arialRegular, $c-white);
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .statistics {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .statistics {
    &__number {
      font-size: $font-standard;
    }
    &__text {
      font-size: $font-l;
    }
  }
}
