.general {
  @include Shape(700px, 100%);
  @include Flex();
  margin: 40px 0;
  gap: 30px;
  padding: 30px;
  box-sizing: border-box;
  &__container {
    @include Flex();
    @include Shape(100%, initial);
    @include JustifyContent(space-evenly);
    flex-direction: column;
    flex: 1;
  }
  &__box {
    border-left: 2px solid black;
    padding-left: 20px;
  }
  &__title {
    @include Font($font-standard, initial, initial, $f-arialRegular, $c-black);
    margin-bottom: 20px;
  }
  &__description {
    @include Font($font-standard, initial, initial, $f-arialRegular, $c-black);
  }
  &__image {
    height: 700px;
    position: relative;
    bottom: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .general {
    height: 1000px;
    flex-direction: column;
    &__image {
      height: 200px;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 600px) {
  .general {
    padding: 40px 0;
    height: 100%;
    margin: 0;
    &__box {
      margin-top: 20px;
    }
    &__title {
      font-size: $font-standard;
    }
    &__description {
      font-size: $font-l;
    }
    &__image {
      height: 600px;
      position: relative;
      bottom: 0px;
    }
  }
}
